import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "./Backpack.css";
import qs from "qs";
import BackpackBadge from "./BackpackBadge";
import {
  getUserEmail,
  saveBackpackToken,
  getTokenData,
  getID
} from "../../../functions/FirebaseU/FirebaseUtils";
import {setLocalStorage,loadBadgesLocalStorage } from "../../utils.js";

const clientID = process.env.REACT_APP_CLIENT_ID || "RANDOM";
const returnURL = process.env.REACT_APP_RETURN_URL;
const testing_api = process.env.REACT_APP_TESTING_API || "no"


class Backpack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      email: "",
      password: "",
      backpackBadges: null,
      isLoaded: false,
      attempt: 0,
      attemptMsg: false,
      error: false,
      getting: false,
      gettingAccess: false,
      infoError: "",
      calltoaction: "",
    };
    this.validateCredentials = this.validateCredentials.bind(this);
    this.postMount = this.postMount.bind(this);
    this.getCode = this.getCode.bind(this);
    this.isLogged = this.isLogged.bind(this);
    this.getBadges = this.getBadges.bind(this);
    this.getNewToken = this.getNewToken.bind(this);
    this.replaceMulti = this.replaceMulti.bind(this);
    //this.loadBadgesLocalStorage = this.loadBadgesLocalStorage.bind(this);
    this.saveBackpackLocalStorage = this.saveBackpackLocalStorage.bind(this);
  }

  async validateCredentials() {
    window.location.replace(
      `https://badgr.io/auth/oauth2/authorize?client_id=${clientID}&redirect_uri=${returnURL}&scope=r:profile r:backpack`
    );
  }

  replaceMulti(str, findings, replacings) {
    var newStr = str;
    var regex;

    for (var i = 0; i < findings.length; i++) {
      if (findings[i] === ".") {
        newStr = newStr.replace(/\./g, replacings[i]);
      } else if (findings[i] === "*") {
        newStr = newStr.replace(/\./g, replacings[i]);
      } else {
        regex = new RegExp(findings[i], "g");
        newStr = newStr.replace(regex, replacings[i]);
      }
    }
    return newStr;
  }

  async componentWillMount() {
    const email = await getUserEmail();
    const [error,info,badgeArray] = loadBadgesLocalStorage(email.email + "-badges");
    if(error){
      console.info(info)
    }
    this.setState({ backpackBadges: badgeArray });
  }

  async componentDidMount() {
    const email = await getUserEmail();
    this.setState({ email: email.email });
    var data;
    getTokenData(email.email, email.email).once("value", async (snapshot) => {
      data = snapshot.val();
      console.log(data)
      this.postMount(snapshot.val(), email);
      if (!data) await this.getCode(email);
    });
  }

  async isLogged(token) {
    const isLogged = await axios.post(`/users/logged`, {
      data: token,
    });
    return isLogged;
  }

  async getBadges(token) {
    this.setState({ isAuthenticated: true });
    const badges = await axios.post(`/users/backpack`, {
      token: token.data.access_token,
    });
    if (badges && badges !== {}) {
      console.log("BACKPACK'S:  ", badges)
      this.setState({ backpackBadges: badges });
    }
  }

  async getCode(email) {
    var code=false;
    if(this.props.location.search){
      code=qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code 
      console.log("CODE FROM URL: ",code)     
    }
    if (code) {
      console.log("CODE'S FLOW")
      var res = await axios.post("/users/oauthToken", { body: { code: code } });
      if (res.data && res.data.access_token) {
        console.log("TOKEN CODE: ", res.data)
        await this.postMount(res, email);
        //await this.getBadges(res);
      }
    }
    this.setState({isLoaded: true });
  }

  async refreshToken(attempt, email, token) {
    if(attempt < 2){
      const new_access = await axios.post(`/users/refresh`, {
        token: token.data.refresh_token,
      });
      console.log("NEW ACCESS: ",new_access.data.status)
      if(new_access.data.status=='400' ||new_access.data.status=='429'){
        attempt=3;
        console.error("Error New Access", new_access.data, attempt);
        return attempt;
      }
      const [error, infoError] = saveBackpackToken(
        email.email,
        new_access.data,
        this.state.email
      );
      if (error) {
        this.setState({attemptMsg: true})
        attempt++;
        console.error("Error", infoError, attempt);
        return attempt;
      } else {
        this.setState({ isAuthenticated: true });
        const badges = await axios.post(`/users/backpack`, {
          token: new_access.data.access_token,
        });
        if (badges && badges !== {}) {
          this.saveBackpackLocalStorage(badges);
          return attempt;
        }
      }
      attempt++
      this.setState({attempt:attempt})
      await this.refreshToken(attempt, email, new_access.data)
      return attempt;
    }
    return 0;
  }

  async getNewToken(email, token) { 
    let attempt = 0;
    let maxAttempts = 2;
    if (this.props.location.search.length<=0) {
      attempt = await this.refreshToken(attempt, email, token)
      console.log(attempt)
    }else{
      console.log("ELSE GET NEW")
      this.setState({ gettingAccess: true });
      //HERE THIS NEW ACCESS
      const new_access = await axios.post(`/users/refresh`, {
        token: token.data.refresh_token,
      });
      console.log("NEW ACCESS", new_access);
      if(new_access.data.access_token){
       
        //const new_access = {data: {access_token:""}}
        const [error, infoError] = saveBackpackToken(
          email.email,
          new_access.data,
          this.state.email
        );
    
        if (error) {
          console.error("Error", infoError);
          //await this.getNewToken(email,token)
        } else {
          this.setState({ isAuthenticated: true, getting: true, gettingAccess: false });
          //this.setState({error: false})
          const badges = await axios.post(`/users/backpack`, {
            token: new_access.data.access_token,
          });
          if (badges && badges !== {}) {
            this.saveBackpackLocalStorage(badges);
            this.setState({ getting: false });
          }
        }
      }else{
        this.setState({ isLoaded: true, isAuthenticated: false, error:true, infoError:"Sorry, unable to get access, try again" });
      }
    }
   
    if (attempt > maxAttempts) {
      this.setState({attemptMsg: false})
      if (this.state.backpackBadges && this.state.backpackBadges.data.badges.length != 0) {
        this.setState({
          error: true,
          infoError:
            "Backpack Loaded from Cache: The backpack can't be loaded in this moment please try again later",
          calltoaction: "Refresh",
        });
      } else {
        this.setState({
          error: true,
          infoError:
            "The backpack can't be loaded in this moment please try again later",
          calltoaction: "Reconnect",
        });
      }
    }
  }

  async postMount(token, email) {
    if (token) {
      console.log("POSTMOUN TOKEN", token)
      const isLogged = await this.isLogged(token);
      console.log("POSTMOUNT ISLOGGED", isLogged);
      if (isLogged.data === true) {
        await this.getBadges(token);
      } else if (isLogged.data === false) {
        await this.getNewToken(email, token);
      }
    }
  }

  saveBackpackLocalStorage(badges) {
    let savedStr = "";
    for (let x = 0; x < badges.data.badges.length; x++) {
      savedStr +=
        badges.data.badges[x].id +
        "@" +
        badges.data.badges[x].name +
        "@" +
        badges.data.badges[x].description +
        "|";
    }

    savedStr = savedStr.substring(0, savedStr.length - 1);
    setLocalStorage(this.state.email + "-badges", savedStr);
    this.setState({ backpackBadges: badges });
  }

  render() {
    return (
      <div>
        <div className="badge-summary jumbotron">
          <h1>Backpack</h1>
        </div>
        <div className="body-app">
          {this.state.attemptMsg && (
            <p>{`Getting Backpack: ${this.state.attempt}/2`}</p>
          )}
          {this.state.error && (
            <div className="d-flex flex-column">
              <span className="font-weight-bold m-1">
                {this.state.infoError}
              </span>
              
              {this.state.calltoaction && (<button
                className="btn btn-primary btn-sm w-25"
                //Here?
                onClick={() => this.validateCredentials()}
              >
                {this.state.calltoaction}
              </button>)}
            </div>
          )}
          {!this.state.isAuthenticated && this.state.isLoaded ? (
            <div className="Credentials">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.validateCredentials()}
              >
                Login with Badgr's OAuth
              </button>
            </div>
          ) : (
            <div className="row">
              <div>
                {this.state.getting ? <p>Getting Backpack...</p> : <></>}
                {this.state.gettingAccess ? <p>Getting Access...</p> : <></>}
              </div>
              {this.state.backpackBadges
                ? this.state.backpackBadges.data.badges.map((badge) => {
                  return (
                    <BackpackBadge badge={badge}></BackpackBadge>
                  )})
                : !this.state.error && (
                    <div>
                      <p>Refreshing...</p>
                    </div>
                  )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Backpack);
