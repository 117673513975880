import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { deletePathwayFromGroup } from "../../../functions/FirebaseU/FirebaseUtils";

const getID = (str) => str.substring(str.lastIndexOf("/") + 1);

const getKey = (pathways, item) => {
  const list = Object.keys(pathways).filter(
    (ID) => pathways[ID] === getID(item.completionBadge)
  );
  return list.length > 0 ? list[0] : "";
};

export const PathwayTable = ({ paths, groupid, pathways }) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [pathID, setPathID] = useState("");

  return (
    <table className="table mt-3">
      <Modal show={open} onHide={setOpen}>
        <Modal.Header>
          <h5 class="modal-title">Remove Pathway</h5>
        </Modal.Header>
        <Modal.Body>
          <p>{`Are you sure you want to remove ${title} pathway on this group?`}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              pathID != ""
                ? deletePathwayFromGroup(groupid, pathID)
                : alert("UNEXPECTED ERROR: NO KEY AVAILABLE");
              setOpen(!open);
            }}
          >
            Yes, remove
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => setOpen(!open)}
          >
            No, don't remove
          </button>
        </Modal.Footer>
      </Modal>
      <thead class="thead-dark">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        {paths.map((item) => (
          <tr key={getID(item.completionBadge)}>
            <th scope="row">{item.title}</th>
            <td>
              <Link
                className="btn btn-primary"
                to={`/pathway/${getID(item.completionBadge) ? getID(item.completionBadge) : item.title}`}
              >
                View
              </Link>
              <button
                style={{marginLeft: "10px"}}
                className="btn btn-danger"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setOpen(true);
                  setTitle(item.title);
                  setPathID(getKey(pathways, item));
                }}
              >
                Remove
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
