import React from 'react'
import IsAdminValidation from '../components/Auth/isAdmin'
import AppNavbar from '../AppNavbar'

//Generic Component for Add more logic begfore validate the admin
export const NavbarWithAdmin = ({...props}) => {
    return (
        <IsAdminValidation component={AppNavbar} {...props} />
    )
}
