import axios from "axios";

//getUserId: Transform the URL to a ID of the badge
const getID = (str) => str.substring(str.lastIndexOf("/") + 1);

//getLocalStorage : get value from local storage
export const getLocalStorage = (key) =>{
    try {
        const item = window.localStorage.getItem(key);
        return item;
    } catch (e) {
        console.error(e);
        return null;
    }
}

//setLocalStorage : set value in local storage
export const setLocalStorage = (key,value) => {
    try {
        window.localStorage.setItem(key,value);
    } catch (e) {
        console.error(e);
    }
}

//replaceMulti : 
const replaceMulti = (str, findings, replacings) => {
  var newStr = str;
  var regex;

  for (var i = 0; i < findings.length; i++) {
    if (findings[i] === ".") {
      newStr = newStr.replace(/\./g, replacings[i]);
    } else if (findings[i] === "*") {
      newStr = newStr.replace(/\./g, replacings[i]);
    } else {
      regex = new RegExp(findings[i], "g");
      newStr = newStr.replace(regex, replacings[i]);
    }
  }
  return newStr;
}

//saveBackpack : Recive the token,cancelToken Object and the name of the localstorage var
export const saveBackpack = async (token, cancelToken,localStorageSpace) => {
    let badges;
    try{
        badges = await axios.post(`/users/backpack`, 
          {token: token},
          {cancelToken: cancelToken.token}
        );
  
        let savedStr = "";
        for (let x = 0; x < badges.data.badges.length; x++) {
          savedStr += getID(badges.data.badges[x].id) + "|";
        }
        savedStr = savedStr.substring(0, savedStr.length - 1);
        setLocalStorage(localStorageSpace, savedStr);
      }catch(error){
        console.log(error);
      }
    return badges
}

//loadBadgesLocalStorage : Load badges from local storage
export const loadBadgesLocalStorage=(from)=>{
  console.log("Getting badges from ",from)
  let badgesStored = getLocalStorage(from);
  let badgeArray = { data: { badges: [] } };

  if (badgesStored) {
    let badgesIDS = replaceMulti(
      badgesStored,
      ["%40", "%2D", "%5F", "%2F", "%2E", "%2A", "%20", "%22", "%2", "%7C"],
      ["@", "-", "_", "/", ".", "*", " ", '"', ",", "|"]
    ).split("|");
    badgesIDS.forEach((badge) => {
      let badgeStr = replaceMulti(
        badge,
        ["%40", "%2D", "%5F", "%2F", "%2E", "%2A", "%20", "%22", "%2", "%7C"],
        ["@", "-", "_", "/", ".", "*", " ", '"', ",", "|"]
      ).split("@");
      badgeArray.data.badges.push({
        id: badgeStr[0],
        name: badgeStr[1],
        description: badgeStr[2],
      });
    });
    return [false,"",badgeArray]
  }
  return [true,"Not badges in the localstorage fetching...",badgeArray]
}
