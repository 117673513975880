import { webhooksRef, snapshotToArray } from "../firebase";

export const getAllWebhooks = async (owner) => {
  let response = [];
  let error = null;
  await webhooksRef
    .orderByChild("owner")
    .equalTo(owner)
    .once("value", (snapshot) => {
      response = snapshotToArray(snapshot);
    })
    .catch((e) => {
      error = e;
    });
  return [response, error];
};
