import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import BadgeHeader from '../components/BadgeHeader/BadgeHeader';
import BadgeContent from '../components/BadgeContent/BadgeContent';
import AwardSection from '../components/AwardSection/AwardSection';
import Loading from "react-fullscreen-loading";
import {ToastsContainer, ToastsStore} from 'react-toasts';
import {
    getID
  } from "../../functions/FirebaseU/FirebaseUtils";
  
class AwardContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            badgeToken: '',
            issuerData: {},
            badgeData: {criteria:{}, id: ""},            
            query: {},
            display: '',
            isLoading: true,
            disabled: false,

        }
    }

   
    handleAwardBadge = async(e) => {
        e.preventDefault();
        
            await axios.post(
                `/award`,
                {
                    email: this.state.query.email,
                    badgeToken: this.state.badgeToken,
                    badgeName: this.state.badgeData.name,
                }
            )
            .then(res => {
                if(!res.data.status.success){
                    ToastsStore.error(res.data.status.description)
                   console.log(res)
                }else{
                    ToastsStore.success('Badge has been awarded!')
                }
                this.setState({
                    display: 'd-none'
                })
            })
            .catch(err => {
                console.log(err)
            })
    }

    async componentDidMount() {
        const { match: {params}} = this.props
        this.setState({
            query: QueryString.parse(this.props.location.search),
            badgeToken: params.badge_token
        })  
        let dataBadge = await axios.get(`/badges/${params.badge_token}`)
        this.setState({badgeData: dataBadge.data})
        let issuerURL = dataBadge.data.issuer;
        let issuerID = issuerURL.substring(issuerURL.lastIndexOf('/') + 1)
        let dataIssuer = await axios.get(`/issuer/${issuerID}`)
        if(dataIssuer)
            this.setState({issuerData: dataIssuer.data, isLoading: false})
    }

    render() {
        return (
            <div>
                <Loading loading={this.state.isLoading} background="#d8d8e6" loaderColor="#525dc7" />
                <BadgeHeader imageSource={getID(this.state.badgeData.id)} badgeName={this.state.badgeData.name}
                    badgeDescription={this.state.badgeData.description} issuerURL={this.state.badgeData.issuer} 
                    issuerName={this.state.issuerData?this.state.issuerData.name:""} 
                    display="d-none" openModal={this.openModal} showButton={false}/>
                <BadgeContent criteriaNarrative={this.state.badgeData.criteria.narrative ? this.state.badgeData.criteria.narrative : ""} 
                    criteriaURL={this.state.badgeData.criteria.id ? this.state.badgeData.criteria.id : ""} />
                <AwardSection handleAwardBadge={this.handleAwardBadge} display={this.state.display} email={this.state.query.email} 
                    evidence={this.state.query.evidence} disabled={this.state.disabled}/>
                <ToastsContainer store={ToastsStore}/>
            </div>
        )
        
    }
}

export default withRouter(AwardContainer);