import React,{ Component} from 'react'
import {logIn, isLogged, getUserEmail} from '../../../functions/FirebaseU/FirebaseUtils'

export default class LogIn extends Component{
    constructor(props){
        super(props)
        this.state = {user: ""}
    }

    async componentDidMount(){
        const user = await getUserEmail();
        if(user) {
            this.setState({user: user.email});
            document.location.href = "/";
        }
    }

    render(){
        return(
            <div className="App badge-summary jumbotron">
                <h1>Welcome to Badgeways</h1>
                <button className="btn btn-primary" onClick={() => logIn()}>Log In</button>
            </div>
        )
    }
}