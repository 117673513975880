import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { AddWebHook } from "../../components/AddWebhook";
import { EditWebhook } from "../../components/EditWebhook";
import { getAllWebhooks } from "../../api/Webhooks/getAllWebhooks";
import { deleteWebhook } from "../../api/Webhooks/deleteWebhook";
import { getUserEmail } from "../../../functions/FirebaseU/FirebaseUtils";

export const WebHookPage = React.memo(() => {
  const [webhooks, setWebhooks] = useState([]);
  const [webhook, setWebhook] = useState({
    key: "",
    name: "",
    url: "",
    secret: "",
    issuer: "",
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      const user = await getUserEmail();
      const [response, errorInfo] = await getAllWebhooks(user.email);
      if (errorInfo) {
        console.log(errorInfo);
      }
      setWebhooks(response);
    };
    init();
  });

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const handleDelete = async (id) => {
    await deleteWebhook(id);
  };
  const handleEdit = async (webhook) => {
    setWebhook(webhook);
    setShowEditModal(true);
  };

  return (
    <div>
      <div className="badge-summary jumbotron">
        <h1>Webhooks Management</h1>
      </div>
      <div className="body-app">
        <Button
          variant="primary"
          style={{ marginBottom: "15px" }}
          onClick={() => setShowAddModal(!showAddModal)}
        >
          Add Webhook
        </Button>
        <table className="table mt-3">
          <thead class="thead-dark">
            <tr>
              <th>Issuer ID</th>
              <th>Name</th>
              <th>URL</th>
              <th>Event</th>
              <th>Secret</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {webhooks ? (
              webhooks.map((webhook) => (
                <tr key={webhook.key}>
                  <td>{webhook.issuer}</td>
                  <td>{webhook.name}</td>
                  <td>{webhook.url}</td>
                  <td>{webhook.event}</td>
                  <td>{webhook.secret}</td>
                  <td>
                    <div class="btn-group" role="group">
                      <button
                        type="button"
                        className="btn btn-primary mr-3"
                        onClick={() => handleEdit(webhook)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(webhook.key)}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div />
            )}
          </tbody>
        </table>
      </div>
      <AddWebHook showModal={showAddModal} closeModal={handleCloseModal} />
      {webhooks.length > 0 && (
        <EditWebhook
          showEditModal={showEditModal}
          closeEditModal={closeEditModal}
          key={webhook.key}
          webhookInfo={webhook}
        />
      )}
    </div>
  );
});
