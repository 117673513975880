import React from 'react'
import IsAdminValidation from '../components/Auth/isAdmin'
import Explore from '../components/Dashboard/Dashboard'

//Generic Component for Add more logic begfore validate the admin
export const ExploreWithAdmin = ({...props}) => {
    return (
        <IsAdminValidation component={Explore} {...props} />
    )
}
