import React, { Component } from "react";
import "./App.css";
import BadgeContainer from "./containers/BadgeContainer";
import AwardContainer from "./containers/AwardContainer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/Auth/PrivateRoute";
import LogIn from "./components/Auth/Login";
import {NavbarWithAdmin} from "./containers/NavbarWithAdmin"
import ListGroup from "./components/Groups/ListGroup";
import NewGroup from "./components/Groups/NewGroup";
import IndividualGroup from "./components/Groups/IndividualGroup";
import {PathwayWithAdmin} from "./containers/PathwayWithAdmin"
import {ExploreWithAdmin} from "./containers/ExploreWithAdmin"
import Backpack from "./components/Backpack/Backpack";
import {WebHookPage} from "./view/WebhookPage"

class App extends Component {
  render () {
      return (
        <Router>
          <div className="h-100">
            <NavbarWithAdmin/>
            <Switch>
              <PrivateRoute  path='/explore' component={ExploreWithAdmin} />
              <Route path='/badges/:badge_token' component={BadgeContainer}/>
              <Route path='/award/:badge_token' component={AwardContainer}/>
              <Route path='/login' component={LogIn}/>
              <PrivateRoute exact component={ExploreWithAdmin} path='/pathway' />
              <PrivateRoute admin={true} component={WebHookPage} path='/webhooks' />
              <PrivateRoute admin={true} strict exact component={ListGroup} path='/groups' />
              <PrivateRoute admin={true} strict exact component={NewGroup} path='/groups/new'/>
              <PrivateRoute admin={true} strict exact component={NewGroup} path='/groups/edit/:id' />
              <PrivateRoute admin={true} strict exact component={IndividualGroup} path='/groups/:id' />
              <PrivateRoute component={PathwayWithAdmin} path="/pathway/:pathway_id" />
              <PrivateRoute component={Backpack} path='/' />
            </Switch>
          </div>
        </Router>
      );
    }
}

export default App;
