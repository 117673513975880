import React from "react";
import {
  getOneGroup,
  addUserToGroup,
  addPathwayToGroup,
} from "../../../functions/FirebaseU/FirebaseUtils";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import { WebhookFire } from "../Webhooks/WebhookEngine";
import Modal from "react-bootstrap/Modal";
import { UserTable } from "../UserTable";
import { PathwayTable } from "../PathwayTable";
import { Loader } from "../Loader";
import "./IndividualGroup.css";
import { isUndefined } from "lodash";
import axios from 'axios'

const getID = (str) => str.substring(str.lastIndexOf('/') + 1);

class IndividualGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      desc: "",
      users: null,
      pathways: undefined,
      allPathways: [],
      open: false,
      openPath: false,
      email: "",
      userError:"",
    };
    this.handleModal = this.handleModal.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.addUser = this.addUser.bind(this);
    this.pathwayAdded = this.pathwayAdded.bind(this);
  }

  handleModal() {
    this.setState({ open: !this.state.open });
  }

  handleModalPath() {
    this.setState({ openPath: !this.state.openPath });
  }

  componentDidMount() {
   
     // TODO  .json replaced by get data in database
    axios.get('/pathways').then((response) => {
      let allPathways = response.data
      const {
        match: { params },
      } = this.props;
  
      this.setState({ id: params.id });
  
      getOneGroup(params.id).on("value", (snapshot) => {
        try {
          if (snapshot.val()) {
            const { name, description, pathways,users } = snapshot.val();
            this.setState({
              name: name,
              desc: description,
              users: users,
              pathways: pathways,
            });
            if (users === null || isUndefined(users)) {
              this.setState({ users: [] });
            }
            if (isUndefined(pathways)) {
              this.setState({ pathways: [] });
            }
          }
        } catch (error) {
          console.log("NO GROUP", error);
        }
      });
  
      this.setState({
        allPathways,
      });
    })
  }

  onChangeText = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({
      ...this.state,
      userError: "",
      [name]: value,
    });
  };

  addUser() {
    const exist = Object.values(this.state.users).filter((value) => value.email === this.state.email).length == 0;
    if(exist){
      if(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.state.email)){
        addUserToGroup(this.state.id, this.state.email);
        axios
          .post('/invite', { payload:{
            name: this.state.name,
            email: this.state.email
          } })
          .catch((error) => console.log(error))
        this.setState({email: ""});
        document.getElementById("userEmail").focus();
      }else{
        alert("Please write a valid email");
      }
    }else{
      alert("The user is already in the group")
    }
  }
  

  pathwayAdded(item) {
    addPathwayToGroup(this.state.id, getID(item.completionBadge));
    if(this.state.users){
      Object.values(this.state.users).map(user => 
        axios
          .post('/newpathway', { payload:{
            pathwayid: getID(item.completionBadge),
            pathname: item.title,
            groupname: this.state.name,
            email: user.email
          } })
          .catch((error) => console.log(error))
      )
    }
  }

  render() {
    let pathList = [];
    let modalList = []
    if(this.state.pathways && this.state.allPathways){
      const groupPaths = Object.values(this.state.pathways)
      pathList = this.state.allPathways.filter(value => groupPaths.includes(getID(value.completionBadge)))
      modalList = this.state.allPathways.filter(value => !groupPaths.includes(getID(value.completionBadge)))
    }

    return (
      <div>
        <div className="badge-summary jumbotron row d-flex justify-content-around">
          <div className="text-left">
            <h1>{this.state.name}</h1>
            <p>{this.state.desc}</p>
          </div>
          <div>
            <Link
              to={{
                pathname: `/groups/edit/${this.state.id}`,
                aboutProps: {
                  name: this.state.name,
                  desc: this.state.description,
                  id: this.state.id,
                },
              }}
              className="btn btn-primary mr-3"
            >
              Edit
            </Link>

            <Link to={`/groups`} className="btn btn-secondary">
              Back
            </Link>
          </div>
        </div>
        <div className="body-app">
          <div>
            <div style={{ marginTop: "3%" }}>
              <Tabs style={{ width: "100%" }}>
                <Tab eventKey="available" title="Users">
                  {this.state.users ? (
                    <div>
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => this.handleModal()}
                      >
                        Add User
                      </button>
                      <UserTable groupid={this.state.id} users={(this.state.users)} />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Tab>
                <Tab eventKey="pathways" title="Pathways">
                  {!isUndefined(this.state.pathways) ? (
                    <div>
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => this.handleModalPath()}
                      >
                        Add Pathway
                      </button>
                      <PathwayTable groupid={this.state.id} paths={pathList} pathways={this.state.pathways}/>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
          <Modal show={this.state.open} onHide={this.handleModal}>
            <Modal.Header>
              <h4 class="modal-title">Add new user to group</h4>
            </Modal.Header>
            <Modal.Body>
              <input
                placeholder="Email"
                name="email"
                id="userEmail"
                value={this.state.email}
                onChange={this.onChangeText}
                type="email"
                required
              ></input>
              {
                this.state.userError&&<p className="center">{this.state.userError}</p>
              }
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => this.handleModal()}
              >
                Close
              </button>
              <button
                className="btn btn-primary"
                onClick={() => this.addUser()}
              >
                Add User
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.openPath} onHide={this.handleModalPath}>
            <Modal.Header>
              <h4 class="modal-title">Add New Pathway to Group</h4>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              <div className="pathwaysModal">
                {modalList.map((item) => (
                  <div className=" d-flex flex-row m-2 shadow p-4 align-middle justify-content-between align-content-center align-items-center">
                    <span className="font-weight-bold">{item.title}</span>
                    <button
                      className="btn btn-primary ml-3"
                      onClick={() => this.pathwayAdded(item)}
                    >
                      Add
                    </button>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => this.handleModalPath()}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
export default IndividualGroup;
