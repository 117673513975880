import { groupsRef } from "../firebase";

export const getAllGroups = async () => {
  let groups = [];
  let error= false;
  await groupsRef
    .once("value", (snapshot) => {
      groups = snapshot.val();
    })
    .catch((e) => {
      error = e;
    });
  return [groups,error];
};
