import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useInputValue } from "../../hooks/useInputValue";
import Button from "react-bootstrap/Button";
import { addWebhook } from "../../api/Webhooks/addWebhook";
import { getUserEmail } from "../../../functions/FirebaseU/FirebaseUtils";

export const AddWebHook = ({ showModal, closeModal }) => {
  const name = useInputValue("");
  const url = useInputValue("");
  const secret = useInputValue("");
  const issuer = useInputValue("");
  const [disabled, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [event, setEvent] = useState("Badge Awarded");

  const validateFields = () => {
    let error = false;
    if (issuer.value === "") {
      return [(error = true), "The Issuer field it's required"];
    }
    if (event === "") {
      return [(error = true), "The event field it's required"];
    }
    if (url.value === "") {
      return [(error = true), "the URL field it's required"];
    }
    return [error, ""];
  };

  const HandleAddWebhook = async () => {
    const user = await getUserEmail();
    const [validationError, errorInfo] = validateFields();
    if (!validationError) {
      const error = await addWebhook({
        issuer: issuer.value,
        owner: user.email,
        event,
        secret: secret.value,
        name: name.value,
        url: url.value,
      });
      if (error) {
        setError(error);
      } else {
        closeModal();
      }
    } else {
      setError(errorInfo);
      setDisable(true);
    }
  };
  return (
    <Modal show={showModal} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Webhook</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="webhook.issuer">
            <Form.Label>Issuer ID</Form.Label>
            <Form.Control value={issuer.value} onChange={issuer.onChange} />
          </Form.Group>
          <Form.Group controlId="webhook.name">
            <Form.Label>Name</Form.Label>
            <Form.Control value={name.value} onChange={name.onChange} />
          </Form.Group>
          <Form.Group controlId="webhook.url">
            <Form.Label>URL</Form.Label>
            <Form.Control value={url.value} onChange={url.onChange} />
          </Form.Group>
          <Form.Group controlId="webhook.secret">
            <Form.Label>Secret</Form.Label>
            <Form.Control value={secret.value} onChange={secret.onChange} />
          </Form.Group>
          <Form.Group controlId="webhook.event">
            <Form.Label>Event</Form.Label>
            <Form.Control
              as="select"
              value={event}
              onChange={(e) => setEvent(e.target.value)}
            >
              <option>Badge Awarded</option>
              <option>Badge Requested</option>
              <option>Group Invitation</option>
              <option>Pathway Join Requested</option>
              <option>New Pathway</option>
              <option>New Pathway Member</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {error && (
          <div class="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={disabled}
          onClick={HandleAddWebhook}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
