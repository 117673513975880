import { webhooksRef } from "../firebase";

const eventToName = (key) => {
  const lowString = key.toLowerCase();
  return lowString.replace(/\s+/g, "_");
};

export const addWebhook = async ({
  issuer,
  owner,
  event,
  secret,
  name,
  url,
}) => {
  let error = null;

  webhooksRef
    .push({
      issuer,
      name,
      event: eventToName(event),
      owner,
      secret,
      url,
    })
    .catch((e) => {
      error = e;
      console.error(e);
    });

  return error;
};
