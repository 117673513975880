import firebase from "firebase";
import envs from "../config";

const config = {
  apiKey: envs.REACT_APP_FB_APIKEY,
  authDomain: envs.REACT_APP_FB_AUTHDOM,
  databaseURL: envs.REACT_APP_FB_DATABASE,
  projectId: envs.REACT_APP_FB_PROJECTID,
  storageBucket: envs.REACT_APP_FB_STOREBUCKET,
  messagingSenderId: envs.REACT_APP_FB_SENDER,
  appId: envs.REACT_APP_FB_APPID,
  measurementId: envs.REACT_APP_FB_MEASURE,
};
//Initialize Firebase
export let app;
if (!firebase.apps.length) {
  app = firebase.initializeApp(config);
}else {
  app = firebase.app(); // if already initialized, use that one
}
//DB:
export const db = app.database();
//Db Refs:
export const webhooksRef = db.ref("/webhooks");
export const groupsRef = db.ref("/groups");
//methods:
export const snapshotToArray = (snapshot) => {
  const returnArr = [];
  snapshot.forEach(function (childSnapshot) {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });
  return returnArr;
};
