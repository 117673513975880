import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Link } from "react-router-dom";
import {getUserEmail, getGroups} from '../../../functions/FirebaseU/FirebaseUtils'
import './Dashboard.css'
import { Loader } from '../Loader';
import axios from 'axios'
const getID = (str) => str.substring(str.lastIndexOf('/') + 1);

class PathwayCard extends Component{
    constructor(props){
        super(props);
    }

    render(){
        var badgeID = getID(this.props.pathway.completionBadge);
        return (
            <div class="col-sm-6">
                <div className="card" style={{marginTop: "15px"}}>
                    <h5 className="card-header">{this.props.pathway.title}</h5>
                    <div className="card-body">
                        <div>
                            <Link
                                className="btn btn-primary btn-sm" 
                                to={`/pathway/${badgeID.length>0?badgeID:this.props.pathway.title}`}
                            >
                                View
                            </Link>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            allpathways: [], 
            pathways: [],
            userEmail: "", 
            my_pathways: [],
            isAdmin: false,
        };
        this.findUser = this.findUser.bind(this)
    }

    async componentWillMount() {
        this.setState({
            isAdmin: this.props.isAdmin,
        });
    }

    async componentDidMount(){
        window.onpopstate  = (e) => {
            window.location.reload();
        }
        axios.get('/pathways').then(async (allPathways) => {

          this.setState({allpathways: allPathways.data})
          const user = await getUserEmail();
          this.setState({userEmail: user.email});
  
          getGroups().on("value", async (snapshot) => {
              try {
                if (snapshot.val()) {
                  this.setState({ isLoading: false });
                  const groups = Object.values(snapshot.val()).filter((grp) => grp.users && grp.pathways && this.findUser(Object.values(grp.users), user.email))
                  const paths = groups.map(group => Object.values(group.pathways))
                  localStorage.setItem('paths', JSON.stringify(paths))

                  let jsonObject = paths.flat().map(JSON.stringify);
                  let uniqueSet = new Set(jsonObject); 
                  let uniqueArray = Array.from(uniqueSet).map(JSON.parse); 
                  const filtered = allPathways.data.filter(a=>uniqueArray.includes(getID(a.completionBadge)));
                  localStorage.setItem('filtered', JSON.stringify(filtered))
                  this.setState({pathways: filtered});
                }
              } catch (error) {
                console.log("NO GROUPS",error);
              }
          });
        })
    }
    
    findUser(users, email){
        return users.filter((u) => u.email === email).length > 0
    }

    render(){
        return (
            <div>
                <div className="badge-summary jumbotron">
                <h1>Pathways</h1>
                </div>
                <div className="body-app">
                    <Tabs style={{width: "100%"}}>
                        {this.props.isAdmin?
                            <Tab eventKey="available" title="Available">
                                <div className="row">
                                    {!this.state.allpathways.length?
                                        <div class="col-sm-6">
                                            <br/>
                                            <Loader/>
                                        </div>
                                        :
                                        this.state.allpathways.map((pathway) => 
                                            <PathwayCard pathway={pathway} data={this.state}/>
                                        )
                                    }
                                </div>
                            </Tab>:
                            <></>
                        }
                        <Tab eventKey="my_pathways" title="My Pathways">
                            <div className="row">
                                {!this.state.pathways.length?
                                    <div class="col-sm-12">
                                        <br/>
                                        <span>There are currently no pathways here</span>
                                    </div>
                                    :
                                    this.state.pathways.map((pathway) =>
                                        <PathwayCard pathway={pathway} data={this.state}/>
                                    )
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )
    }
}

export default Dashboard