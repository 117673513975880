import React from "react";
import $ from "jquery";
import axios from "axios";
import { Link } from "react-router-dom";
import { createPathway } from "./NodeGraph";
import Cookie from "js-cookie";
import { getAllGroups } from "../../api/Groups/getAllGroups";
import {
  getUserEmail,
  getUserBackpack,
  saveBackpackToken,
  ONE_DAY,
} from "../../../functions/FirebaseU/FirebaseUtils";
import {
  getLocalStorage,
  setLocalStorage,
  saveBackpack,
} from "../../utils.js";
import "./Pathway.css";
import { rgb } from "d3";
import CircularBar from "./CircularBar";
const testing_api = process.env.REACT_APP_TESTING_API;

const getID = (str) => {
  if (str) {
    return str.substring(str.lastIndexOf("/") + 1);
  }
};
let cancelToken;

class Pathway extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathway: {},
      userEmail: null,
      awarded: {},
      dataAward: {},
      groups: null,
      group: null,
      userToken: null,
      isAdmin: false,
      loading: false,
      userLoading: false,
      noBackpack: false,
      groupID: null,
      loadingBadgr: false,
      infoError: "",
      calltoaction: "",
      error: false,
      changeUserLoading: false,
      pathwayPercentage: 0,
      totalRequired: 0,
      userPercentage: 0
    };
    this.findEarned = this.findEarned.bind(this);
    this.checkAwarded = this.checkAwarded.bind(this);
    this.changeGroup = this.changeGroup.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.clearPathway = this.clearPathway.bind(this);
    this.getUserToken = this.getUserToken.bind(this);
    this.getUserAssertions = this.getUserAssertions.bind(this);
    this.saveCookies = this.saveCookies.bind(this);
    this.readCookies = this.readCookies.bind(this);
    this.replaceMulti = this.replaceMulti.bind(this);
    this.clearRequireCache = this.clearRequireCache.bind(this);

    this.loadBackpackIds = this.loadBackpackIds.bind(this);
    this.refreshUserToken = this.refreshUserToken.bind(this);
    this.getIdsString = this.getIdsString.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.cleanError = this.cleanError.bind(this);
  }

  cleanError() {
    if (!this.state.isAdmin) {
      this.setState({ userLoading: true });
    }
    this.setState({
      loading: true,
      error: false,
      infoError: "",
      calltoaction: "",
    });
  }

  clearRequireCache() {
    Object.keys(require.cache).forEach(function (key) {
      delete require.cache[key];
    });
  }
  replaceMulti(str, findings, replacings) {
    var newStr = str;
    var regex;

    for (var i = 0; i < findings.length; i++) {
      if (findings[i] === ".") {
        newStr = newStr.replace(/\./g, replacings[i]);
      } else if (findings[i] === "*") {
        newStr = newStr.replace(/\./g, replacings[i]);
      } else {
        regex = new RegExp(findings[i], "g");
        newStr = newStr.replace(regex, replacings[i]);
      }
    }
    return newStr;
  }

  async componentWillMount() {
    this.setState({
      isAdmin: this.props.isAdmin,
    });
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const pathways = (await axios.get("/pathways")).data;
    const currPathway = (await axios.get(`/pathways/${params.pathway_id}`)).data;
    this.setState({
      pathway: currPathway,
      isAdmin: this.props.isAdmin,
    });
    localStorage.setItem("pathway", JSON.stringify(currPathway));
    const groupInCache = this.readCookies("group");
    const userInCache = this.readCookies("user_email");

    localStorage.setItem("pathway", JSON.stringify(currPathway));
    createPathway(currPathway, pathways);

    const [groups, error] = await getAllGroups();
    if (error) {
      console.error("Error getting groups: ", error);
    }
    this.setState({ groups: groups });
    if (groupInCache && userInCache) {
      const pathwaysOfCurrentGroup = groups[groupInCache].pathways;
      const exists = pathwaysOfCurrentGroup
        ? Object.values(pathwaysOfCurrentGroup).includes(
          getID(this.state.pathway.completionBadge)
        )
        : false;
      if (exists && userInCache !== "0") {
        this.setState(
          {
            groupID: groupInCache,
            userEmail: userInCache,
            group: groups[groupInCache],
            loading: true,
            loadingBadgr: true,
          },
          async () => {
            await this.getUserToken(userInCache);
          }
        );
      } else {
        this.clearPathway(this.state.pathway);
      }
    }
    const user = await getUserEmail();
    if (!this.state.isAdmin) {
      this.setState({ userLoading: true });
      await this.getUserToken(user.email);
      if (!this.state.userEmail) this.setState({ userEmail: user.email });
    }
  }

  checkAwarded(pathway, awards) {
    cancelToken = undefined;
    if (pathway) {
      let badgeId = "";
      let badgeIdTwo = "";
      if (pathway.completionBadge && pathway.requiredBadge) {
        badgeId = getID(pathway.completionBadge);
        badgeIdTwo = getID(pathway.requiredBadge);
        let newV = this.state.totalRequired + 1;
        this.setState({totalRequired : newV});
      } else if (pathway.completionBadge) {
        badgeId = getID(pathway.completionBadge);
      } else if (pathway.requiredBadge) {
        badgeId = getID(pathway.requiredBadge);
        let newV = this.state.totalRequired + 1;
        this.setState({totalRequired : newV});
      }
      const awardIds = awards.map((a) => {
        return getID(a);
      });
      if (this.findEarned(badgeId, awardIds)) {
        $(`#check_${badgeId}`).attr("visibility", "visible");
        if(pathway.requiredBadge && !pathway.completionBadge){
          let newV = this.state.pathwayPercentage + 1;
          this.setState({pathwayPercentage : newV})
        }
      }
      if (badgeIdTwo) {
        if (this.findEarned(badgeIdTwo, awardIds)) {
          $(`#check_${badgeIdTwo}`).attr("visibility", "visible");
          let newV = this.state.pathwayPercentage + 1;
          this.setState({pathwayPercentage : newV})
        }
      }
      if (pathway.pathwayURL) {
        for (let x = 0; x < pathway.length; x++) {
          if (getID(pathway[x].completionBadge) === getID(pathway.pathwayURL)) {
            if (pathway[x].children) {
              for (let y = 0; y < pathway[x].children.length; y++) {
                this.checkAwarded(pathway[x].children[y], awards);
              }
            }
          }
        }
      }
      if (pathway.children) {
        for (let i = 0; i < pathway.children.length; i++) {
          this.checkAwarded(pathway.children[i], awards);
        }
      }
      this.setState({ loading: false, userLoading: false });
    }
  }

  findEarned(badgeId, awards) {
    if (badgeId && badgeId !== " ") {
      if (awards[0].id) {
        return awards.filter((a) => a.id === badgeId).length > 0;
      } else {
        return awards.filter((a) => a === badgeId).length > 0;
      }
    }
  }

  changeGroup(event) {
    this.setState({
      group: this.state.groups[event.target.value],
      groupID: event.target.value,
      userEmail: "User...",
    });
    this.setState({
      userPercentage: 0,
      pathwayPercentage: 0,
      totalRequired: 0
    })
    this.saveCookies("group", event.target.value);
    this.clearPathway(this.state.pathway);
  }

  //Save Cookie
  saveCookies(key, value) {
    Cookie.set(key, value);
  }
  //Read Cookie
  readCookies(key) {
    let readCookie;
    try {
      readCookie = Cookie.get(key);
    } catch (e) {
      console.error(e);
    }
    return readCookie;
  }

  changeUser(event) {
    this.setState({ totalRequired: 0});
    this.setState({ pathwayPercentage: 0});
    this.setState({ userPercentage: 0});
    if (typeof cancelToken != typeof undefined) {
      this.setState({ loading: true});
      this.setState({ totalRequired: 0});
      this.setState({ pathwayPercentage: 0});
      cancelToken.cancel(
        "Operation canceled due to new request.(UPDATE BACKPACK)"
      );
    }
    this.clearPathway(this.state.pathway);
    this.setState({
      userLoading: true,
      loading: true,
      userEmail: event.target.value,
      pathwayPercentage: 0,
      totalRequired: 0
    });
    this.saveCookies("user_email", event.target.value);

    this.getUserToken(event.target.value);
  }
  async getUserAssertions(token, email) {
    if (typeof cancelToken != typeof undefined) {
      this.setState({ loading: true });
      this.setState({ totalRequired: 0});
      this.setState({ pathwayPercentage: 0});
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    let badges = await saveBackpack(token, cancelToken, "user_progress-" + email);
    
    this.saveCookies("user_email", email);
    return badges;
  }

  async getUserProgress(email) {
    await getUserBackpack(email)
      .once("value")
      .then(async (snapshot) => {
        const res = this.checkToken(snapshot, email);
        return res;
      })
      .then(async (token) => {
        return await this.getUserAssertions(token, email);
      })
      .then((res) => {
        if(res.data.badges.length === 0){
          alert("This user has no backpack yet")
        }
        this.getIdsString(res);
      })
      .then(() => {
        this.getUserPercentage();
      })
      .catch((error) => {
        if (typeof cancelToken == typeof undefined) {
          this.setState({ totalRequired: 0});
          this.setState({ pathwayPercentage: 0});
          this.setState({
            noBackpack: true,
            loading: false,
            userLoading: false,
          });
        } else {
          this.clearPathway(this.state.pathway);
          console.log("ERROR: ", error);
          let badgesStored = getLocalStorage("user_progress-" + this.state.userEmail);
          if (badgesStored) {
            this.loadBackpackIds(badgesStored);
          }
        }
      });
  }

  async getUserToken(email) {
    this.cleanError();
    let badgesStored = getLocalStorage("user_progress-" + email);
    window.localStorage.setItem("progress_owner", email);
    if (email !== "0") {
      if (
        this.readCookies("user_email") ===
        window.localStorage.getItem("progress_owner") &&
        badgesStored
      ) {
        this.loadBackpackIds(badgesStored);
        this.setState({pathwayPercentage:0})
        this.setState({totalRequired:0})
        await this.getUserProgress(email);
      } else {
        await this.getUserProgress(email);
      }
    }else{
      this.setState({userPercentage: 0});
      this.setState({loading: false});
    }
  }

  loadBackpackIds(badgesStored) {
    this.setState({ userLoading: true, loading: true });

    let savedStr = "";
    let badgeArray = { data: { badges: [] } };
    let badgesIDS = this.replaceMulti(
      badgesStored,
      ["%40", "%2D", "%5F", "%2F", "%2E", "%2A", "%20", "%22", "%2", "%7C"],
      ["@", "-", "_", "/", ".", "*", " ", '"', ",", "|"]
    ).split(",");
    badgesIDS.forEach((badge) => {
      badgeArray.data.badges.push({ id: badge });
    });

    for (let x = 0; x < badgeArray.data.badges.length; x++) {
      savedStr += badgeArray.data.badges[x].id + ",";
    }

    savedStr = savedStr.substring(0, savedStr.length - 1);

    this.clearPathway(this.state.pathway);
    this.setState({ loadingBadgr: false });
    this.setState({ userLoading: true, loading: true });
    this.checkAwarded(this.state.pathway, savedStr.split(","));
  }

  async refreshUserToken(backpack, email) {
    let attempts = 0;
    if (typeof cancelToken != typeof undefined) {
      this.setState({ loading: true});
      this.setState({ totalRequired: 0});
      this.setState({ pathwayPercentage: 0});
      cancelToken.cancel(
        "Operation canceled due to new request.(UPDATE BACKPACK)"
      );
    }
    let new_access = { data: {} };
    while (attempts <= 4) {
      cancelToken = axios.CancelToken.source();
      try {
        new_access = await axios.post(
          `/users/refresh`,
          { token: backpack.data.refresh_token },
          { cancelToken: cancelToken.token }
        );
        //new_access = { data: {} };
      } catch (error) {
        console.log(error);
      }

      const [error, infoError] = saveBackpackToken(
        email,
        new_access.data,
        email
      );

      if (error) {
        attempts++;
        console.error("Error", infoError, attempts);
      } else {
        return new_access.data.access_token;
      }
    }
    if (attempts >= 4) {
      console.log("ERRORS, MAX ATTEMPTS");
      this.setState({
        loading: false,
        userLoading: false,
        error: true,
        infoError: "Unable to get data",
        calltoaction: "Try Again",
      });
      return {};
    }
  }

  getIdsString(res) {
    let savedStr = "";
    for (let x = 0; x < res.data.badges.length; x++) {
      savedStr += res.data.badges[x].id + ",";
    }
    savedStr = savedStr.substring(0, savedStr.length - 1);
    window.localStorage.setItem("user_progress-" + this.state.userEmail, savedStr);
    window.localStorage.setItem("progress_owner", this.state.userEmail);
    this.clearPathway(this.state.pathway);
    this.setState({ loadingBadgr: false });
    this.setState({ loading: false });
    this.checkAwarded(this.state.pathway, savedStr.split(","));
  }

  getUserPercentage(){
    let newPercentage = (this.state.pathwayPercentage / this.state.totalRequired) * 100;
    newPercentage = Math.round(newPercentage);
    this.setState({userPercentage: newPercentage})
  }

  checkToken(snapshot, email) {
    if (snapshot.val() !== null) {
      const backpack = snapshot.val();

      var d = new Date();
      var time = d.getTime();
      if (testing_api === "yes" || time - backpack.issuedOn > ONE_DAY) {
        return this.refreshUserToken(backpack, email);
      }

      return backpack.data.access_token;
    } else {
      if (this.state.isAdmin) {
        this.setState({ loading: false, userLoading: false });
        //alert("This user has no backpack yet");
      }
    }
    return {};
  }

  clearPathway(pathway) {
    //setLocalStorage("user_progress", "");
    if (pathway) {
      const badgeId = getID(
        pathway.completionBadge
          ? pathway.completionBadge
          : pathway.requiredBadge
            ? pathway.requiredBadge
            : ""
      );
      if (badgeId !== "") {
        $(`#check_${badgeId}`).attr("visibility", "hidden");
        if (pathway.completionBadge) {
          $(`#${badgeId}`).attr("stroke", "#ffdd00");
        } else {
          $(`#${badgeId}`).attr("stroke", "#535dc8");
        }
      }
      if (pathway.pathwayURL) {
        for (let x = 0; x < pathway.length; x++) {
          if (getID(pathway[x].completionBadge) === getID(pathway.pathwayURL)) {
            if (pathway[x].children) {
              for (let y = 0; y < pathway[x].children.length; y++) {
                this.clearPathway(pathway[x].children[y]);
              }
            }
          }
        }
      }
      if (pathway.children) {
        for (let i = 0; i < pathway.children.length; i++) {
          this.clearPathway(pathway.children[i]);
        }
      }
    }
  }

  render() {
    var g = [];
    var new_g = {};
    if (this.state.groups) {
      for (let group in this.state.groups) {
        for (let pathway in this.state.groups[group].pathways) {
          if (
            this.state.groups[group].pathways[pathway] ==
            getID(this.state.pathway.completionBadge)
          ) {
            g.push(this.state.groups[group]);
            new_g[group] = this.state.groups[group];
          }
        }
      }
    }
    var t = "auto";
    if (document.getElementById("topPart")) {
      t =
        document.getElementById("topPart").offsetHeight +
        document.getElementById("topPart").offsetTop;
      if (!this.state.group) {
        t += 24;
      }
      if (this.state.loading) {
        t += 7;
      }
    }
    return (
      <div style={{ height: "100%-56px" }}>
        <div id="topPart">
          <div className="badge-summary jumbotron m-0 p-1">
            <h1 className="h3"></h1>
          </div>
          {this.state.isAdmin ? (
            <div className="d-flex flex-nowrap row mt-1">
              <div className="row w-50 pl-2">
                {this.state.groups && this.state.isAdmin && (
                  <div className="input-group justify-content-end">
                    <select
                      className="w-50"
                      id="groupSelect"
                      onChange={this.changeGroup}
                      value={this.state.groupID}
                    >
                      <option selected value="0">
                        Group...
                      </option>
                      {Object.entries(new_g).map(([key, value]) => {
                        return <option value={key}>{value.name}</option>;
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="row w-50">
                {this.state.group && this.state.isAdmin && (
                  <div className="input-group pl-2">
                    {
                      this.state.group.users ?
                        (<select
                          className="w-50"
                          id="userSelect"
                          onChange={this.changeUser}
                          value={this.state.userEmail}
                        >
                          <option selected value="0">
                            User...
                          </option>
                          {
                            this.state.group.users ? (
                              Object.entries(this.state.group.users).map(
                                ([key, value]) => {
                                  return (
                                    <option value={value.email}>{value.email}</option>
                                  );
                                }
                              )) : <option value="nothing">No users</option>
                          }
                        </select>) : <p></p>
                    }
                    {this.state.loading ? (
                      <p className="ml-3">Fetching...</p>
                    ) : (
                      <></>
                    )}
                    {
                      window.localStorage.getItem("progress_owner") !== "0" && this.state.userEmail !== "User..."?
                        <CircularBar value={this.state.userPercentage} hidden={false}></CircularBar>
                      :
                        <CircularBar value={this.state.userPercentage} hidden={true}></CircularBar>
                    }
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex flex-nowrap row">
            <div style={{ marginLeft: "5%" }}>
              <ul className="mb-0">
                <li style={{ color: "#535dc8" }}>Not Awarded</li>
                <li style={{ color: "#ffdd00" }}>Completion Badge</li>
                <li style={{ color: "#13bf00" }}>Awarded</li>
              </ul>
              {this.state.loading && !this.state.isAdmin ? (
                <p className="ml-3">Fetching...</p>
              ) : (
                <></>
              )}
              {this.state.noBackpack && !this.state.isAdmin ? (
                <div>
                  <p>
                    Please register your backpack
                    <Link to="/backpack">here</Link>
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
            {
              this.state.group && !this.state.group.users ? (
                <p style={{
                  backgroundColor: "rgb(254,240,177)",
                  textAlign: "center",
                  margin: "1%",
                  marginLeft: "5% !important",
                  paddingLeft: "1%",
                  paddingRight: "1%",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderRadius: "5px",
                  borderColor: "rgb(240,214,95)"
                }}>
                  The chosen group is currently empty. Please add one or more users to it before verifying progress on the pathway.
                </p>
              ) : <p></p>
            }
          </div>
          {this.state.error && (
            <div style={{ marginLeft: "5%" }}>
              <p style={{ display: "inline-block" }}>{this.state.infoError}</p>
              <button
                className="btn btn-primary"
                style={{ display: "inline-block", marginLeft: "10px" }}
                onClick={() => this.getUserToken(this.state.userEmail)}
              >
                {this.state.calltoaction}
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            width: "100vw",
            top: t,
          }}
        >
          <div
            className="pathway-div w-100 m-0"
            style={{
              textAlign: "center",
              overflow: "auto",
              height: this.state.loading
                ? "72vh"
                : this.state.loadingBadgr
                  ? "72vh"
                  : "73.5vh",
            }}
          >
            <div id="my_dataviz" />
          </div>
        </div>
      </div>
    );
  }
}
export default Pathway;
