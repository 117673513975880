import React, { useState }from 'react'
import {deleteUserFromGroup} from '../../../functions/FirebaseU/FirebaseUtils'
import Modal from "react-bootstrap/Modal";

export const UserTable = ({groupid, users}) => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState(false);
    const [key, setKey] = useState(false);



    return(
        <table className="table mt-3">
            <Modal show={open} onHide={setOpen}>
                <Modal.Header>
                    <h5 class="modal-title">Removing User</h5>
                </Modal.Header>
                <Modal.Body>
                    <p>{`Are you sure you want to remove the user with this email ${email} ?`}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        className="btn btn-primary" 
                        onClick={() => {deleteUserFromGroup(groupid, key); setOpen(!open);}}
                    >
                        Yes, remove
                    </button>
                    <button 
                        type="button" 
                        className="btn btn-secondary" 
                        data-dismiss="modal"
                        onClick={ ()=>setOpen(!open)}
                    >
                        No, don't remove
                    </button>
                </Modal.Footer>
            </Modal>
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
                <tbody>
                {
                    Object.entries(users).map(([key,value])=>(
                        
                        <tr key={key}>
                            <th scope="row">{value.email}</th>
                            <td>{new Date(value.ms_date).toLocaleDateString("en-US")}</td>
                            <td>
                                <button 
                                    className="btn btn-danger" 
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEmail(value.email);
                                        setKey(key);
                                        setOpen(true);}}
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                        )
                    )
                }
                </tbody>
        </table>
    )
}