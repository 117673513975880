import * as d3 from "d3";
import $ from "jquery";

const getID = (str) => str.substring(str.lastIndexOf("/") + 1);

let Y_OFFSET = [];
let LANES = 0;
let PATHWAYOBJ = new Object();
let ID = 0;
let tall = 0;

///TREE FUNCTIONS

function treeDeep(obj) {
  let h = 0;
  const heights = [];

  if (obj.children) {
    if (obj.children.length > 1) tall += obj.children.length - 1;
    for (let index = 0; index < obj.children.length; index++) {
      heights.push(treeDeep(obj.children[index]));
    }

    h = Math.max(...heights);
  }
  return h + 1;
}

function down2Up(obj) {
  const lvls = treeDeep(obj);
  let id = ID;
  ID++;
  Y_OFFSET = new Array(lvls);
  Y_OFFSET.fill(0);
  LANES = lvls;

  PATHWAYOBJ["nodes"] = [];
  PATHWAYOBJ["links"] = [];

  if (obj.children) {
    for (let index = 0; index < obj.children.length; index++) {
      down2Up_aux(lvls - 1, obj.children[index], (lvls - 1) * 384, 43, id);
    }
  }
  const node = {};
  //Here it's selecting the image, we need to add both images to the node
  let urlCompletionBadge = obj.completionBadge;
  let urlRequiredBadge = obj.requiredBadge;
  node["id"] = id;
  node["name"] = obj.title;
  node["y"] = 0;
  node["x"] = (lvls - 1) * 384;
  node["complete_url"] = urlCompletionBadge || "";
  node["required_url"] = urlRequiredBadge || "";
  node["isComplete"] = has2urls(obj);
  node["pathwayURL"] = obj.pathwayURL || "";
  //Here we validate if this exist or not
  if (urlCompletionBadge)
    node["imageCompletionBadge"] = `https://api.badgr.io/public/badges/${getID(
      urlCompletionBadge
    )}/image`;
  if (urlRequiredBadge)
    node["imageRequiredBadge"] = `https://api.badgr.io/public/badges/${getID(
      urlRequiredBadge
    )}/image`;

  PATHWAYOBJ["nodes"].push(node);
  renderGraph(PATHWAYOBJ);
  $("h1").html(obj.title + " Pathway");
}

function has2urls(obj) {
  if (obj.completionBadge && obj.requiredBadge) {
    return 2;
  } else if (obj.completionBadge) {
    return 1;
  } else if (obj.requiredBadge) {
    return 0;
  }
  return -1;
}

function down2Up_aux(level, obj, xdes, ydes, iddes) {
  let myY = getY(false);
  let id = 0;
  const found = PATHWAYOBJ["nodes"].filter((node) => node.name === obj.title);
  if (found.length > 0) {
    id = found[0].id;
  } else {
    id = ID;
    ID++;
  }

  if (obj.children && found.length == 0) {
    for (let index = 0; index < obj.children.length; index++) {
      down2Up_aux(
        level - 1,
        obj.children[index],
        (level - 1) * 384,
        myY * 129 + 43,
        id
      );
    }
  } else {
    myY = getY(true);
  }

  const xori = (level - 1) * 384 + 256;
  const yori = myY * 129 + 43;

  const link = {};
  link["source"] = id;
  link["target"] = iddes;
  if (found.length > 0) {
    const foundlink = PATHWAYOBJ["links"].filter(
      (link) => link.source === found[0].id
    );
    link["xori"] = foundlink[0].xori;
    link["yori"] = foundlink[0].yori;
    link["xdes"] = xdes;
    link["ydes"] = ydes;
  } else {
    link["xori"] = xori;
    link["yori"] = yori;
    link["xdes"] = xdes;
    link["ydes"] = ydes;
  }
  PATHWAYOBJ["links"].push(link);

  if (found.length == 0) {
    const node = {};
    const urlCompletionBadge = obj.completionBadge;
    const urlRequiredBadge = obj.requiredBadge;

    node["id"] = id;
    node["name"] = obj.title;
    node["y"] = myY * 129;
    node["x"] = (level - 1) * 384;
    node["complete_url"] = obj.completionBadge || "";
    node["required_url"] = obj.requiredBadge || "";
    node["isComplete"] = has2urls(obj);
    node["pathwayURL"] = obj.pathwayURL || "";
    if (urlCompletionBadge)
      node[
        "imageCompletionBadge"
      ] = `https://api.badgr.io/public/badges/${getID(
        urlCompletionBadge
      )}/image`;
    if (urlRequiredBadge)
      node["imageRequiredBadge"] = `https://api.badgr.io/public/badges/${getID(
        urlRequiredBadge
      )}/image`;

    PATHWAYOBJ["nodes"].push(node);
  }
}

function getY(end) {
  let pos = Y_OFFSET[0];
  if (end) Y_OFFSET[0] += 1;
  return pos;
}

export function createPathway(pathway, pathways) {
  PATHWAYOBJ = {};
  modify(pathway, pathways);
  down2Up(pathway);
  PATHWAYOBJ["lanes"] = LANES;
  PATHWAYOBJ["tall"] = tall + 1;
}

function modify(pathway, pathways) {
  if (pathway) {
    const newChildren = [];
    if (pathway.children) {
      for (let index = 0; index < pathway.children.length; index++) {
        let newChild = modifyaux(pathway.children[index], pathways);
        if (newChild) newChildren.push(newChild);
      }
    }
  }
}

function modifyaux(pathway, pathways) {
  let newPathway = pathway;
  if (pathway) {
    const newChildren = [];
    let oldChildren = [];
    if (pathway.pathwayURL && pathway.pathwayURL !== "") {
      let childPathway = pathways.filter(
        (path) => getID(path.completionBadge) === getID(pathway.pathwayURL)
      );
      if (childPathway.length > 0 && childPathway[0].children) {
        newPathway.title = pathway.title ? pathway.title.split("-").join(" "):childPathway[0].title.split("-").join(" ");
        if (!pathway.requiredBadge) {
          if(pathway.completionBadge){
            newPathway.completionBadge=pathway.completionBadge;
          }else if(pathway.completionBadge===""){
            newPathway.completionBadge="";
          }else{
            newPathway.completionBadge= childPathway[0].completionBadge;
          }
        }

        if (!pathway.completionBadge) {
          if(pathway.requiredBadge){
            newPathway.requiredBadge=pathway.requiredBadge;
          }else if(pathway.requiredBadge===""){
            newPathway.requiredBadge="";
          }else{
            newPathway.requiredBadge= childPathway[0].requiredBadge;
          }
        }

        if (!newPathway.children) {
          if (!newPathway.completionBadge && newPathway.requiredBadge && childPathway[0].completionBadge) {
            newPathway.completionBadge = childPathway[0].completionBadge;
          }
          newPathway.children = childPathway[0].children;
        } else {
          oldChildren = newPathway.children;
          newPathway.children = childPathway[0].children;
          newPathway = addChildrenAtDeep(oldChildren, newPathway);
        }
      }
    }

    if (newPathway.children) {
      for (let index = 0; index < newPathway.children.length; index++) {
        let newChild = modifyaux(newPathway.children[index], pathways);
        if (newChild) newChildren.push(newChild);
      }
      newPathway.children = newChildren;
    }
  }

  return newPathway;
}

function addChildrenAtDeep(oldChildren, pathway) {
  let newPathway = pathway;

  if (newPathway) {
    if (newPathway.children && newPathway.children.length > 0) {
      for (let index = 0; index < newPathway.children.length; index++) {
        addChildrenAtDeep_aux(oldChildren, newPathway.children[index]);
      }
    } else {
      newPathway["children"] = oldChildren;
    }
  }

  return newPathway;
}

function addChildrenAtDeep_aux(oldChildren, pathway) {
  let newPathway = pathway;

  if (newPathway) {
    if (newPathway.children) {
      const newChildren = [];
      for (let index = 0; index < newPathway.children.length; index++) {
        let child = addChildrenAtDeep_aux(
          oldChildren,
          newPathway.children[index]
        );
        if (child) newChildren.push(child);
      }
      newPathway["children"] = newChildren;
    } else {
      newPathway["children"] = oldChildren;
    }
  }
  return newPathway;
}

async function renderGraph(data) {
  const margin = { top: 10, right: 30, bottom: 30, left: 40 },
    width = LANES * 384 - margin.left - margin.right,
    height = Y_OFFSET[0] * 129 - margin.top - margin.bottom;

  const svg = d3
    .select("#my_dataviz")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .attr("style", "background-color:#F2F2F2")
    .append("g")
    .attr("opacity", 0)
    .attr("word-wrap", "break-word")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  const link = svg
    .selectAll("path")
    .data(data.links)
    .enter()
    .append("path")
    .style("stroke", "#aaa")
    .style("stroke-width", "4")
    .attr("fill", "none")
    .attr("d", function (d) {
      const half = d.xori + (d.xdes - d.xori) / 2 ;
      return (
        "M" +
        d.xori +
        "," +
        d.yori +
        " L " +
        half +
        "," +
        d.yori +
        " L " +
        half +
        "," +
        d.ydes +
        " L " +
        d.xdes +
        "," +
        d.ydes
      );
    });

  const node = svg
    .selectAll("rect")
    .data(data.nodes)
    .enter()
    .append("rect")
    .attr("width", 256)
    .attr("height", 86)
    .attr("stroke-width", "3")
    .attr("stroke", "#535dc8")
    .attr("fill", "white")
    .attr("id", function (d) {
      let completionUrl = d.completion_url || "";
      let requiredUrl = d.required_url || "";

      if (completionUrl && requiredUrl) {
        return getID(completionUrl);
      } else if (completionUrl) {
        return getID(completionUrl);
      } else if (requiredUrl) {
        return getID(requiredUrl);
      } else {
        return "";
      }
    })
    .attr("x", function (d) {
      return d.x;
    })
    .attr("y", function (d) {
      return d.y;
    })
    .attr("rx", 4)
    .attr("ry", 4)
    .attr("stroke", function (d) {
      return d.isComplete == 1 || d.isComplete == 2 ? "#ffdd00" : "#535dc8";
    })
    .on("click", handleClick);

  const imageCompletionBadge = svg
    .selectAll("image")
    .data(data.nodes)
    .enter()
    .append("image")
    .attr("width", "48")
    .attr("height", "48")
    .attr("x", function (d) {
      if (d.isComplete) return d.x + 192;
      else return d.x + 10;
    })
    .attr("y", function (d) {
      return d.y + 17;
    })
    .attr("xlink:href", function (d) {
      return d.imageCompletionBadge;
    })
    .append("png:image");

  const imageRequiredBadge = svg
    .selectAll("imageTwo")
    .data(data.nodes)
    .enter()
    .append("image")
    .attr("width", "48")
    .attr("height", "48")
    .attr("x", function (d) {
      if (d.isComplete && !d.imageRequiredBadge) return d.x + 192;
      else return d.x + 10;
    })
    .attr("y", function (d) {
      return d.y + 17;
    })
    .attr("xlink:href", function (d) {
      return d.imageRequiredBadge;
    })
    .append("png:image");

  const checkimages = svg
    .selectAll("checkimages")
    .data(data.nodes)
    .enter()
    .append("image")
    .attr("width", "15")
    .attr("height", "15")
    .attr("visibility", "hidden")
    .attr("id", function (d) {
      let completionUrl = d.complete_url || "";
      let requiredUrl = d.required_url || "";

      if (completionUrl && requiredUrl) {
        return `check_${getID(completionUrl)}`;
      } else if (completionUrl) {
        return `check_${getID(completionUrl)}`;
      } else if (requiredUrl) {
        return `check_${getID(requiredUrl)}`;
      } else {
        return "";
      }
    })
    .attr("x", function (d) {
      return d.x + 239.5;
    })
    .attr("y", function (d) {
      return d.y + 1.5;
    })
    .attr("xlink:href", "/group-6.svg");

  const checkimagesTwo = svg
    .selectAll("checkright")
    .data(data.nodes)
    .enter()
    .append("image")
    .attr("width", "15")
    .attr("height", "15")
    .attr("visibility", "hidden")
    .attr("id", function (d) {
      let completionUrl = d.complete_url || "";
      let requiredUrl = d.required_url || "";

      if (completionUrl && requiredUrl) {
        return `check_${getID(requiredUrl)}`;
      }
      return "";
    })
    .attr("x", function (d) {
      return d.x + 1.5;
    })
    .attr("y", function (d) {
      return d.y + 1.5;
    })
    .attr("xlink:href", "/group-6.svg");

  const text = svg
    .selectAll("text")
    .data(data.nodes)
    .enter()
    .append("text")
    .text(function (d) {
      return d.name;
    })
    .attr("x", function (d) {
      return d.x + 64;
    })
    .attr("y", function (d) {
      return d.y + 46;
    })
    .attr("fill", function (d) {
      return d.isComplete == 1 || d.isComplete == 2 ? "#ffdd00" : "#535dc8";
    })
    .attr("fill", "#535dc8");
  $("div.progress-bar").css("display", "none");
  $("div.legend").css("display", "inline-block");
  svg.attr("opacity", 1);

  function handleClick(d) {
    let completionUrl = d.complete_url || "";
    let requiredUrl = d.required_url || "";

    if (completionUrl && requiredUrl) {
      window.location = `/badges/${getID(completionUrl)}`;
    } else if (completionUrl) {
      window.location = `/badges/${getID(completionUrl)}`;
    } else if (requiredUrl) {
      window.location = `/badges/${getID(requiredUrl)}`;
    } else {
      window.location = "#";
    }
  }
}
