import React,{Component} from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./Pathway.css";

class CircularBar extends Component{
  constructor(props) {
    super(props);
  }
  render(){
    return (
    <div className="circular-progress-bar">
      {
        this.props.hidden ?
          <CircularProgressbar value={this.props.value}/>
        :
          <CircularProgressbar value={this.props.value} text={`${this.props.value}%`} />
      }
    </div>
    )
  }
}

export default CircularBar;