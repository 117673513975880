import { db } from "../firebase";

export const editWebhook = async ({
  key,
  issuer,
  name,
  url,
  secret,
  event,
}) => {
  let error = false;
  db.ref(`/webhooks/${key}`)
    .update({
      name,
      issuer,
      url,
      secret,
      event,
    })
    .catch((e) => {
      error = e;
    });
  return error;
};
