import dotenv from "dotenv";
import _each from "lodash/each";
const result = dotenv.config();

let envs;

if (!("error" in result)) {
  envs = result.parsed;
} else {
  envs = {};
  _each(process.env, (value, key) => (envs[key] = value));
}

export default envs;
