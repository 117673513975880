const firebase = require("firebase");
const envs = require("../config");
const Cookie = require("js-cookie");
const env_json = require('../env.json');

const config = {
  apiKey: envs.REACT_APP_FB_APIKEY,
  authDomain: envs.REACT_APP_FB_AUTHDOM,
  databaseURL: envs.REACT_APP_FB_DATABASE ? envs.REACT_APP_FB_DATABASE : env_json.service.db_url,
  projectId: envs.REACT_APP_FB_PROJECTID,
  storageBucket: envs.REACT_APP_FB_STOREBUCKET,
  messagingSenderId: envs.REACT_APP_FB_SENDER,
  appId: envs.REACT_APP_FB_APPID,
  measurementId: envs.REACT_APP_FB_MEASURE,
};

const app = firebase.initializeApp(config);
const googleProvider = new firebase.auth.GoogleAuthProvider();
const getID = (str) => str.substring(str.lastIndexOf("/") + 1);
const ONE_DAY = 86400 * 1000; //86400s to ms

//SESSIONS
const logIn = async () => {
  var signin = await app.auth().signInWithPopup(googleProvider);
  let email = signin.additionalUserInfo.profile.email.replace(/[\.@]/gi, "");

  let userRef = app.database().ref(`/users/${email}`);
  let user = null;

  await userRef.once("value").then(async (snapshot) => {
    user = snapshot.val();
  }).catch(error=>console.log("Error: ",error));

  if (!user) {
    await app
      .database()
      .ref(`/users/${email}`)
      .update({
        permission: { isAdmin: parseInt("0") },
        profile: {
          name: signin.additionalUserInfo.profile.name,
          email: signin.additionalUserInfo.profile.email,
        },
      });
  }

  if (signin) {
    document.location.href = "/";
  }
};

const logOut = () => {
  app.auth().signOut();
  Cookie.remove("group");
  Cookie.remove("user_email");
  Cookie.remove("badges");
  document.location.href = "/login";
};

const isLogged = () => {
  var user = app.auth().currentUser;

  if (user) {
    console.log("IT IS")
  } else {
    console.log("IT IS NOT")
  }
  return "FUNCTION"
}

const saveBackpackToken = (email, data, email2) => {
  var d = new Date();
  var n = d.getTime();
  if(data.access_token){
    app
    .database()
    .ref(
      `/users/${email.replace(/[\.@]/gi, "")}/backpacks/${email2.replace(
        /[\.@]/gi,
        ""
      )}`
    )
    .set({ data, issuedOn: n });
    return [false,""]
  }
  return [true,"Empty information"]
};

const getUserEmail = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = app.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const getUserProfile = (email) => {
  const promiseData = app
    .database()
    .ref(`/users/${email.replace(/[\.@]/gi, "")}`);
  return promiseData;
};

const getUserBackpackToken = (email) => {
  const promiseData = app
    .database()
    .ref(
      `/users/${email.replace(/[\.@]/gi, "")}/backpacks/${email.replace(
        /[\.@]/gi,
        ""
      )}/data/access_token`
    );
  return promiseData;
};

const getUserBackpack = (email) => {
  const promiseData = app
    .database()
    .ref(
      `/users/${email.replace(/[\.@]/gi, "")}/backpacks/${email.replace(
        /[\.@]/gi,
        ""
      )}`
    );
  return promiseData;
};

const getWebhooks = () => {
  const promiseData = app.database().ref("/webhooks");
  return promiseData;
};

const addWebhook = (data) => {
  app.database().ref(`webhooks/${data.id}:${data.name}`).set({
    url: data.url,
    event: data.event,
    secret: data.secret,
    owner: data.email,
  });
};

const deleteWebhook = (value) => {
  app.database().ref(`webhooks/${value}`).remove();
};

const getTokenData = (email, email2) => {
  console.log("emails",email,email2)
  const promiseData = app
    .database()
    .ref(
      `users/${email.replace(/[\.@]/gi, "")}/backpacks/${email2.replace(
        /[\.@]/gi,
        ""
      )}`
    );
  return promiseData;
};

//GROUPS

const addGroup = (name, desc) => {
  app.database().ref(`groups`).push().set({ name: name, description: desc });
};

const editGroup = (id, name, desc) => {
  app.database().ref(`groups/${id}`).update({ name: name, description: desc });
};

const getOneGroup = (id) => {
  const promiseData = app.database().ref(`groups/${id}`);
  return promiseData;
};

const getGroups = () => {
  const promiseData = app.database().ref("/groups");
  return promiseData;
};

const addUserToGroup = (id, email) => {
  var d = new Date();
  var time = d.getTime();
  app.database().ref(`groups/${id}/users`).push().set({
    email: email,
    ms_date: time,
  });
};

const addPathwayToGroup = (id, pathway) => {
  app.database().ref(`groups/${id}/pathways`).push(pathway);
};

const deleteUserFromGroup = (groupid, userid) => {
  app.database().ref(`groups/${groupid}/users/${userid}`).remove();
};

const deletePathwayFromGroup = (groupid, pathwayID) => {
  app.database().ref(`groups/${groupid}/pathways/${pathwayID}`).remove();
};

module.exports = {
  app: app,
  googleProvider: googleProvider,
  ONE_DAY: ONE_DAY,
  logIn: logIn,
  logOut: logOut,
  getID: getID,
  addWebhook: addWebhook,
  deleteWebhook: deleteWebhook,
  getUserEmail: getUserEmail,
  getUserProfile: getUserProfile,
  getWebhooks: getWebhooks,
  getTokenData: getTokenData,
  saveBackpackToken: saveBackpackToken,
  getUserBackpack: getUserBackpack,
  addGroup: addGroup,
  editGroup: editGroup,
  getGroups: getGroups,
  getOneGroup: getOneGroup,
  addUserToGroup: addUserToGroup,
  addPathwayToGroup: addPathwayToGroup,
  deleteUserFromGroup: deleteUserFromGroup,
  getUserBackpackToken: getUserBackpackToken,
  deletePathwayFromGroup: deletePathwayFromGroup,
  isLogged: isLogged
};