import React from "react";
import { app } from "../../../functions/FirebaseU/FirebaseUtils";
import { useObject } from "react-firebase-hooks/database";
import { useAuthState } from "react-firebase-hooks/auth";

//Generic Component for Admin Validation
const IsAdminValidation = ({ component: Component, ...props}) => {
  const [users, usersLoading, userError] = useObject(app.database().ref("/users/"));
  const [user, loading, error] = useAuthState(app.auth());
  const propsNew = {...props}
  propsNew["isAdmin"]=true
  const propsNewNo = {...props}
  propsNewNo["isAdmin"]=false

  return (
    <>
        {
            !loading && !usersLoading? (Object.values(users.val()).filter(
                (item) => item.profile && item.profile.email === user?.email
            )[0]?.permission?.isAdmin === 1 ? (
                <Component {...propsNew}/>
            ) : (<Component  {...propsNewNo}/>)):<></>
        }
    </>
  );
};

export default IsAdminValidation;

